body {
  font-family: 'Aileron', sans-serif;
  color: #fff;
  font-size: 14px;
  /*Updated from 16*/
}

.App {
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}




/* .App {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.App::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: url('Background.webp') no-repeat center center/cover;
  animation: rotateBackground 60s linear infinite;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
}

@keyframes rotateBackground {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
} */

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #070707;
}

.circle {
  position: absolute;
  border-radius: 50%;
  width: 40vw;
  height: 40vw;
}

.circle-yellow {
  left: 2%;
  top: 30%;
  background: #D6CF19;
  animation: animateYellow 30s linear infinite;
}

.circle-purple {
  left: 45%;
  top: -15%;
  background: #9747FF;
  animation: animatePurple 30s linear infinite;
}

.circle-orange {
  left: 2%;
  top: -5%;
  background: #FF6B00;
  animation: animateOrange 30s linear infinite;
}

.circle-blue {
  left: 49%;
  top: 30%;
  background: #0038FF;
  animation: animateBlue 30s linear infinite;
}

@keyframes animateYellow {
  0% {
    left: 2%;
    top: 30%;
    width: 40vw;
    height: 40vw;
  }

  25% {
    left: 2%;
    top: 30%;
    width: 50vw;
    height: 50vw;
  }

  50% {
    left: 45%;
    top: 0%;
    width: 42vw;
    height: 42vw;
  }

  75% {
    left: 10%;
    top: 35%;
    width: 40vw;
    height: 40vw;
  }

  100% {
    left: 2%;
    top: 30%;
    width: 40vw;
    height: 40vw;
  }
}

@keyframes animatePurple {
  0% {
    left: 45%;
    top: -15%;
    width: 40vw;
    height: 40vw;
  }

  25% {
    left: 45%;
    top: -15%;
    width: 45vw;
    height: 45vw;
  }

  50% {
    left: 0%;
    top: 32%;
    width: 45vw;
    height: 45vw;
  }

  75% {
    left: 56%;
    top: -15%;
    width: 45vw;
    height: 45vw;
  }

  100% {
    left: 45%;
    top: -15%;
    width: 40vw;
    height: 40vw;
  }
}

@keyframes animateOrange {
  0% {
    left: 2%;
    top: -5%;
    width: 40vw;
    height: 40vw;
  }

  25% {
    left: 2%;
    top: -5%;
    width: 40vw;
    height: 40vw;
  }

  50% {
    left: 20%;
    top: -5%;
    width: 40vw;
    height: 40vw;
  }

  75% {
    left: 3%;
    top: -50%;
    width: 58vw;
    height: 58vw;
  }

  100% {
    left: 3%;
    top: -5%;
    width: 40vw;
    height: 40vw;
  }
}

@keyframes animateBlue {
  0% {
    left: 49%;
    top: 30%;
    width: 40vw;
    height: 40vw;
  }

  25% {
    left: 49%;
    top: 30%;
    width: 45vw;
    height: 45vw;
  }

  50% {
    left: 52%;
    top: 25%;
    width: 45vw;
    height: 45vw;
  }

  75% {
    left: 73%;
    top: 46%;
    width: 25vw;
    height: 25vw;
  }

  100% {
    left: 49%;
    top: 30%;
    width: 40vw;
    height: 40vw;
  }
}


.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(100px);
  /* Adjust the blur value as needed */
}

@media (max-width: 768px) {

  .right-nav {
    flex-direction: row;
  }

  .right-nav .nav-link {
    padding-left: 15px;
  }

  .circle {
    width: 80vw;
    height: 80vw;
  }

  .circle-yellow {
    left: 5%;
    top: 50%;
  }

  .circle-purple {
    left: 50%;
    top: 10%;
  }

  .circle-orange {
    left: 5%;
    top: 10%;
  }

  .circle-blue {
    left: 50%;
    top: 50%;
  }
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  50% {
    transform: translateY(0) rotate(90deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
    border-radius: 0;
  }

}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.card-header {
  border-bottom: none;
}

.css-18p8bmz-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: none !important;
}

.css-18p8bmz-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: none !important;
}

.App-logo {
  width: 200px;
}

.header-logo {
  width: 119px;
}

/* Login Page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-card {
  width: 100%;
  max-width: 582px;
  padding: 20px 4%;
  /* border-radius: 20px; */
  backdrop-filter: blur(50px);
  margin: 20px;

  /* border: 1px solid rgba(255, 255, 255, 0.40);
  background: rgba(31, 32, 37, 0.70); */

  background: transparent;
  background-image: url('../public/images/card-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}

@media (max-width: 555px) {
  .login-card {
    background-size: cover !important;
  }
}

.policy-card {
  width: 786px !important;
  padding: 0px;
  border-radius: 20px;
  backdrop-filter: blur(50px);
  margin: 20px;

  background: transparent;
  background-image: url('../public/images/card-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  border: none;
}

.small-gary-date {
  margin-top: -4px;
}

.policy-div {
  height: 70vh;
  overflow-y: auto;
  padding: 0px 20px;
}

.inst-p {
  margin-top: 125px;
  margin-bottom: 40px !important;
}

/* Input Field CSS */

.basic-form .form-input {
  background-color: transparent;
  border: 1px solid #48494C;
  border-radius: 10px;
  color: #898889;
  width: 100%;
  font-size: 20px;
  padding: initial;
  padding-left: 15px;
}

.basic-form .form-input:hover,
.basic-form .form-control:focus {
  border: 1px solid #333;
  background-color: transparent;
  color: #898889;
}

.basic-form input,
.basic-form select {
  height: 50px;
}

.basic-form textarea {
  padding-top: 15px !important;
}

.form-input {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  position: relative;
  width: 100%;
  min-height: 56px;
  font-size: 20px;
  padding: 1.1rem .75rem .375rem;
}

.form-input:hover,
.form-control:focus {
  background-color: #2f2f2f;
  border: 1px solid #333;
  color: #fff;
}

.form-input::placeholder {
  color: var(--Word-Grey, #898889);
  font-weight: 400;
  line-height: normal;
  font-family: 'Aileron', sans-serif;
  font-size: 20px;
}

/* Floating label styles */
.floating-label-group {
  position: relative;
  /* margin-bottom: 1em; */
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease all;
  opacity: 0.5;
  color: var(--Word-Grey, #898889);
  /* Match placeholder color */
  font-family: 'Aileron', sans-serif;
  font-size: 13px;
  /* Match placeholder font */
}

.form-input:focus+.floating-label,
.form-input:not(:placeholder-shown)+.floating-label,
.form-input:not(:-ms-input-placeholder)+.floating-label,
/* Internet Explorer 10-11 */
.form-input:not(:-moz-placeholder-shown)+.floating-label {
  /* Firefox */
  top: -0.5rem;
  font-size: 0.75rem;
  opacity: 1;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../public/images/down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  padding-right: 30px;
  color: #317CFF !important;
}

.square-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../public/images/down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 18px 18px;
  padding-right: 30px;
  color: #317CFF !important;
  text-align: center;
}

.float {
  top: 1rem;
  font-size: 14px;
  opacity: 1;
  color: var(--Word-Grey, #898889);
  /* Match placeholder color */
  font-family: 'Aileron', sans-serif;
  /* Match placeholder font */
}

.gray-text {
  color: #898889 !important;
}

.is-invalid {
  border-color: red;
}

.large-text {
  font-size: 22px;
  /*Updated from 24*/
}

.btn-primary {
  background-color: #317CFF;
  transition: background-color 180ms ease-in-out;
}

.btn-primary:hover {
  background-color: #1454C4;
}

.MuiButton-sizeSmall {
  font-size: 16px !important;
  padding: 2px 32px 2px 32px !important;
}

.bold {
  font-weight: bold !important;
}

.close-icon {
  float: right;
  width: 24px;
}

a {
  color: #317CFF;
  text-decoration: none !important;
}

.forgot-password {
  font-size: 16px;
}

.forgot-password:hover,
.privacy-policy:hover {
  color: #0056b3;
}

.privacy-policy {
  color: #317CFF;
  font-size: 16px;
}

.indicator-btn {
  display: inline-flex;
  padding: 1px 6px 1px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Dark-grey, #48494C);
  background: transparent;
  margin-left: 10px;
}

.indicator-btn:hover {
  border: 1px solid var(--Dark-grey, #48494C);
  background: transparent;
}

.theme-btn {
  /* padding: 16px 133px !important; */
  border-radius: 10px !important;
  /* display: flex; */
  height: 56px;
  justify-content: center !important;
  align-items: center !important;
  font-size: 20px !important;
  /* background-color: #007bff;
  border: none; */
}

.theme-btn:hover {
  background-color: #0056b3;
}

.theme-btn-email:hover {
  background-color: #0056b3 !important;
}

.theme-btn-email {
  /* padding: 16px 133px !important; */
  border-radius: 10px !important;
  /* display: flex; */
  height: 42px !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
  background-color: #48494C;
  /*border: none; */
}

.theme-btn-resend {
  /* padding: 16px 133px !important; */
  border-radius: 10px !important;
  /* display: flex; */
  height: 42px !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
  /* background-color: #48494C; */
  /*border: none; */
}

.toggle-password {
  background-color: transparent;
  color: #666;
  cursor: pointer;
  border-left: 0px;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  font-size: 20px;
}

.password-wrapper .form-input:focus+.floating-label+.toggle-password,
.password-wrapper .form-input:not(:placeholder-shown)+.floating-label+.toggle-password,
.password-wrapper .form-input:not(:-ms-input-placeholder)+.floating-label+.toggle-password,
/* Internet Explorer 10-11 */
.password-wrapper .form-input:not(:-moz-placeholder-shown)+.floating-label+.toggle-password {
  /* Firefox */
  opacity: 1;
}

.toggle-password img {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.form-check-label {
  color: #fff;
  font-size: 16px;
}

.form-check-input {
  background-color: unset;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.navbar {
  padding-left: 15px;
  padding-right: 15px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #317CFF;
  font-weight: 700;
}

.nav-link {
  color: #ffffff;
  font-size: 18px;
}

.nav-link:hover,
.nav-link:focus {
  color: lightgray;
  font-size: 18px;
}

.custom-nav-dropdown .dropdown-menu {
  margin-right: 25px;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: rgba(168, 168, 168, 0.10);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(50px);
}

.dropdown-item {
  color: #FFFFFF;
}

.dropdown-toggle::after {
  display: none;
}

.medium-heading {
  font-size: 18px;
  /*Updated from 20*/
}

.text-success {
  color: #40C796 !important;
}

.text-danger {
  color: #E04662 !important;
}

.bg-success {
  background-color: #40C796 !important;
}

.badge {
  display: flex;
  width: 34px;
  padding: 5px 10px 3px 10px;
  border-radius: unset;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 58px;
  }

  .navbar-expand-lg .ml-auto .nav-link {
    padding-left: 15px;
  }

  .toggle-switch {
    padding-right: 25px !important;
  }
}

@media (max-width: 991px),
(max-height: 800px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 30px;
    font-size: 16px;
  }

  .navbar-expand-lg .ml-auto .nav-link {
    padding-left: 15px;
  }

  .toggle-switch {
    padding-right: 25px !important;
  }
}

.estimate-card {
  background-color: rgba(31, 32, 37, 0.70) !important;
  border: 1px solid rgba(255, 255, 255, 0.20);
  border-radius: 10px;
  color: #fff;
  width: 100%;
  overflow: hidden;
  height: calc(100% - 15px);

}

.main-card {
  background-color: rgba(31, 32, 37, 0.70) !important;
  border: 1px solid rgba(255, 255, 255, 0.20);
  border-radius: 10px;
  color: #fff;
  width: 100%;
  overflow: hidden;
  height: 316px;
}

/* .main-card {
  background: transparent;
  background-image: url('../public/images/card-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  height: 316px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  border-radius: 10px !important;
} */

.main-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-gray {
  font-size: 10px;
  /*Updated from 12*/
  color: #A8A8A8;
  font-weight: 400;
}

.list-group-item {
  background-color: transparent;
  color: #FFF;
}

.bid-ask-table th,
.bid-ask-table td {
  border: none;
  white-space: nowrap;
  background-color: transparent;
  color: #FFF;
  padding: 8px 10px;
  line-height: normal;
}

.transaction-table th,
.transaction-table td {
  border: none;
  white-space: nowrap;
  background-color: transparent;
  color: #FFF;
  padding: 8px 10px;
  line-height: normal;
}

.transaction-table th,
.bid-ask-table th {
  color: #898889;
  font-weight: 400;
}

.transaction-table td {
  font-weight: 700;
}

.transaction-select {
  display: inline-flex;
  padding: 4px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #48494C;
  background: transparent;
  color: #FFF;
}

.watchlist-table,
.transaction-table {
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.watchlist-table th {
  font-weight: 400;
}

.watchlist-table td {
  font-weight: 700;
}

.watchlist-table th,
.watchlist-table td {
  border: none;
  white-space: nowrap;
  background-color: transparent;
  color: #FFF;
  padding: 5px 8px;
  line-height: normal;
}

.w-55 {
  width: 55%;
}

.w-65 {
  width: 65%;
  border-radius: 4px;
  font-size: 15px;
  padding: 5px 0px 4px 0px !important;
}

.support-dialog {
  padding: 30px !important;
}

.support-desc {
  height: 186px !important;
}

.submit-btn {
  margin-top: 35px;
  margin-bottom: 10px;
}

.watchlist-table tbody tr:hover:not(.account-info) {
  background-color: #317CFF4D !important;
  border-radius: 4px !important;
}

.watchlist-table .selected-row {
  background-color: rgba(49, 124, 255, 0.20) !important;
  border: 1px solid #317CFF;
}

.watchlist-table .selected-row td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #317CFF;
}

.watchlist-table .selected-row td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #317CFF;
}

.watchlist-table .selected-row td {
  border-top: 1px solid #317CFF;
  border-bottom: 1px solid #317CFF;
}

.news-item {
  padding: 0px;
}

.news-title {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-item:hover {
  color: #317CFF;
  cursor: pointer;
}

.news-description,
.news-source small {
  color: rgba(244, 241, 237, 0.30);
  font-size: 12px;
}

.news-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-data {
  border-bottom: 1px solid rgba(244, 241, 237, 0.30);
  padding: 5px 0px;
}

.sentiment-badge {
  margin-top: 5px;
}

.list-group-item {
  border-bottom: none;
}

.w-t-div {
  max-height: 250px;
  overflow: auto;
}

.m-h-170 {
  max-height: 170px;
  overflow: auto;
}

.m-h-222 {
  max-height: 222px;
  overflow: auto;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.table-responsive {
  overflow-x: auto;
}

.password-field {
  border-right: 0px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  /* border: 1px solid #f6f6f6; */
  padding: 1px 0;
  background-color: transparent;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #48494C;
  /*border: 1px solid #949191;*/
}

.scrollbar-custom::-webkit-scrollbar-corner {
  background: transparent;
}

/* Switch.css */
.switch {
  display: inline-block;
  height: 22px;
  position: relative;
  width: 44px;
  vertical-align: middle;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  background: #ccc;
  border-radius: 34px;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  transition: background-color 0.2s;
  width: 100%;
}

.switch-label .switch-button {
  background: #fff;
  border-radius: 50%;
  bottom: 2px;
  height: 18px;
  left: 3px;
  position: absolute;
  transition: all 0.2s;
  width: 18px;
}

.switch-checkbox:checked+.switch-label {
  background: #317CFF !important;
  /* Change this color to whatever you want */
}

.switch-checkbox:checked+.switch-label .switch-button {
  transform: translateX(20px);
}

.indicator-switch .switch {
  height: 12px !important;
  width: 24px !important;
}

.indicator-switch .switch-label .switch-button {
  bottom: 1px;
  height: 10px;
  width: 10px;
  left: 2px;
}

.indicator-switch .switch-checkbox:checked+.switch-label .switch-button {
  left: -6px !important;
}

/* Modal CSS */
.modal-content {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: rgba(31, 32, 37, 0.70);
  backdrop-filter: blur(20px);
}

Button {
  font-weight: 400 !important;
}

.outlined-btn {
  border-radius: 4px !important;
  border: 1px solid #48494C !important;
  color: #898889 !important;
  text-transform: none !important;
}

.contained-btn {
  background: rgba(49, 124, 255, 0.20) !important;
  border: 1px solid #317CFF !important;
  color: #FFF !important;
  text-transform: none !important;
}

.btn-close {
  background-image: url('../public/images/close.png');
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 586px;
  }

  .login-card {
    min-width: 538px;
    max-width: 582px;
    min-height: 500px;
    max-height: 550px;
  }
}

/* Custom Select */
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 160px;
  margin: 5px 5px;
}

.select-btn {
  background-color: transparent;
  border: 2px solid #48494C;
  border-radius: 4px;
  padding: 3px 10px 3px 10px;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.select-btn-estimate {
  background-color: transparent;
  border: 2px solid #48494C;
  border-radius: 4px;
  padding: 3px 10px 3px 10px;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
  width: 60%;
  text-align: left;
}

.select-options {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 1000;
  margin-top: 2px;

  border-radius: 4px;
  border: 1px solid var(--edge, rgba(255, 255, 255, 0.40));
  background: rgba(168, 168, 168, 0.10);

  /* Blur + Shadow Small */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(50px);
}

.select-preference-options {
  display: block;
  position: absolute;
  z-index: 1000;
  margin-top: 2px;

  border-radius: 4px;
  border: 1px solid var(--edge, rgba(255, 255, 255, 0.40));
  background: rgba(168, 168, 168, 0.10);

  /* Blur + Shadow Small */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(50px);
  max-height: 150px;
  overflow-y: auto;
}

.option {
  padding: 5px 12px;
  cursor: pointer;
}

.option:hover {
  border-radius: 4px;
  border: 1px solid var(--Blue, #317CFF);
  background: rgba(49, 124, 255, 0.20);
}

.scrollbar-custom {
  overflow-y: auto;
}

.btn-group .btn {
  margin: 14px;
  border-radius: 10rem !important;
  font-size: 14px;
  /*Updated from 16*/
  padding: 3px 12px 0px 12px;
  color: #48494C;
  border: 1px solid #48494C;
  background-color: transparent;
}

.btn-group .active {
  background: rgba(49, 124, 255, 0.20);
  border: 1px solid #317CFF;
  color: #317CFF;
}

.ml-0 {
  margin-left: 0px !important;
}

.my-select {
  color: #FFF !important;
  font-size: 18px !important;
  /*Updated from 20*/
  border: none !important;
}

.large-h1 {
  font-size: 46px;
  /*Updated from 20*/
}

/* Make entire slider white */
.MuiSlider-track,
.MuiSlider-rail {
  background-color: #FFF !important;
  color: #FFF !important;
  opacity: 1 !important;
  height: 1px !important;
}

/* Customize slider thumb */
.MuiSlider-thumb {
  width: 7px !important;
  height: 7px !important;
  background-color: #FFF !important;
  /* Make thumb white */
}

/* Customize value label (always visible) */
/* .css-1kqtk5l-MuiSlider-valueLabel {
  background-color: transparent !important;
  color: #FFF !important;
  font-size: 12px !important;
  top: -40px !important;
} */

/* Customize slider marks */
.MuiSlider-mark {
  background-color: unset !important;
}

/* Customize the mark label */
.MuiSlider-markLabel {
  display: none !important;
}

.MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
  background: unset;
  font-size: 16px;
  line-height: 1.23 !important;
}

.MuiSlider-valueLabel {
  -webkit-transform: translateY(-100%) scale(1) !important;
  transform: translateY(-55%) scale(1) !important;
}

.MuiSlider-thumb::after {
  width: 22px !important;
  height: 22px !important;
}

.MuiSlider-root {
  margin-bottom: 0px !important;
}

.f-s-14 {
  font-size: 12px;
  /*Updated from 14*/
}

/* Loader CSS */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.MuiPaper-root {
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.40) !important;
  background: rgba(168, 168, 168, 0.10) !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;
  backdrop-filter: blur(50px) !important;
  color: #FFF !important;
  /* width: 100% !important;
  max-width: 660px !important;
    height: 700px; */
}

/* .MuiPaper-root.MuiPaper-rounded {
  width: 100%;
  backdrop-filter: blur(50px) !important;
  margin: 20px;
  background: transparent;
  background-image: url('../public/images/card-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  color: #FFF !important;
  border-radius: 10px !important;
} */

.indicator-input {
  border-radius: 4px;
  border: 1px solid #48494C;
  background: transparent;
  color: #898889;
}

.indicator-input::placeholder {
  color: var(--Word-Grey, #898889);
  font-weight: 400;
  line-height: normal;
  font-family: 'Aileron', sans-serif;
  font-size: 16px;
}

dl,
ol,
ul {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 0px;
}

li {
  padding: 3px 0px;
}

.indicator-cate-ul li {
  font-size: 14px;
}

.estimate-divider {
  margin: 16px 7px;
}

.border-right {
  border-right: 1px solid #636164;
}

.custom-select .MuiOutlinedInput-root {
  border-radius: 300px;
  /* Circular border as per your requirement */
  padding: 2px 16px;
  /* Adjust the padding to reduce the height */
  min-height: 30px;
  /* Set a smaller height */
  height: auto;
  /* Adjust the height automatically to fit content */
}

.MuiSvgIcon-root {
  display: none !important;
}

.custom-select .MuiSelect-select {
  padding: 2px 10px;
  /* Adjust internal padding to make the field compact */
  padding-right: 5px !important;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border-color: var(--Blue, #317CFF);
  /* Apply the blue border */
  background: rgba(49, 124, 255, 0.20);
  /* Apply the light blue background */
  border-radius: 300px;
  color: #FFF;
}

.square-form-control {
  width: 100%;
}

.square-select .MuiOutlinedInput-notchedOutline {
  border: 1px solid #48494C;
  /* Apply the blue border */
  background: transparent;
  /* Apply the light blue background */
  border-radius: 4px;
}

.square-select .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #48494C;
}

.square-select .MuiSelect-select {
  font-size: 12px !important;
  color: #898889;
  padding: 7px 10px;
  padding-right: 0px !important;
}

.square-form-control input {
  text-align: center;
  font-size: 12px !important;
  color: #898889;
  padding: 7px 10px;
  border: 1px solid #48494C;
  border-radius: 4px;
  background: transparent;
}

/* Override hover and focus styles */
.square-form-control .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.ai-slider .MuiSlider-track {
  color: #1976d2 !important;
  /* Selected portion color */
}

.ai-slider .MuiSlider-rail {
  color: #d3d3d3 !important;
  /* Non-selected portion color */
}

.square-form-control .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.control button {
  width: 100%;
  font-size: 12px;
  border: 1px solid rebeccapurple;
  text-transform: capitalize;
  padding: 5px;
}

.Mui-selected {
  border-radius: 4px !important;
  border: 1px solid var(--Blue, #317CFF) !important;
  background: rgba(49, 124, 255, 0.20) !important;
  margin: 0px 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.MuiMenu-list {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.float-right {
  float: right;
}

/* ProgressBar.css */
.progress-bar-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-size: 10px;
  color: white;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  background-color: #E04662;
  margin-left: 10px;
}

.label.safe {
  background-color: #40C796;
}

.label-blue {
  background-color: #317CFF;
  font-size: 10px;
  color: white;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.label-blue.conservative {
  background-color: #317CFF;
}

.progress-container-portfolio {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.bar-label {
  font-size: 14px;
  color: #898889;
  text-align: left;
}

.bar-label.right {
  text-align: right;
}

.progress-bar-container {
  background-color: #393939;
  border-radius: 30px;
  height: 10px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  margin: 0px 10px;
}

.progress-bar-container-ai {
  background-color: #393939;
  border-radius: 30px;
  height: 10px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.filler {
  height: 100%;
  background-color: #40C796;
  border-radius: 30px 0 0 30px;
  position: relative;
  z-index: 1;
}

.filler.red {
  background-color: #E04662;
}

.filler.blue {
  background-color: #317CFF;
}

.filler.dark-blue {
  background-color: #317CFF;
}

.progress-bar2 {
  background-color: #E04662;
}

.cross-line {
  position: absolute;
  right: -0.5px;
  /* Center the line at the end of the filler */
  top: -1px;
  width: 5px;
  height: 22px;
  background-color: #1e1e1e;
  /* Line color */
  transform: rotate(29deg);
  /* Rotate the line 45 degrees */
  transform-origin: right center;
  /* Ensure the rotation pivots from the right edge */
}

.blue-div {
  border-radius: 10px;
  border: 1px solid #317CFF;
  background: rgba(49, 124, 255, 0.20);
  font-size: 14px;
  color: #317CFF;
  padding: 10px;
}

.form-switch .form-check-input {
  margin-left: 0px;
  --bs-form-switch-bg: url(data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23FFFFFF'/></svg>);
  background-color: #48494C;
  border: none;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.dropdown-btn {
  color: rgb(255, 255, 255);
  background: transparent;
  border: 2px solid #48494C;
  border-radius: 4px;
  margin: 5px 5px;
  padding: 3px 25px 3px 9px
}

.dropdown-btn:hover {
  border: 1px solid #48494C;
}

.legend-item {
  font-size: 12px;
}

.row>* {
  padding-left: 8px;
  padding-right: 8px;
}

.row {
  padding: 0px 15px;
}

.card-large {
  height: 648px;
}

.card-full {
  height: 100%;
}

.home-chart {
  height: 980px;
}

.m-h-320 {
  max-height: 320px;
  overflow: auto;
}

.MuiSlider-sizeMedium {
  padding: 7px 0px !important;
}


/* Styles for Extra large laptop screens */
@media (min-height: 1081px) {
  /* body, .MuiInputBase-input {
    font-size: 11px;
  }

  .medium-heading {
    font-size: 25px !important;
  }

  .small-gray {
    font-size: 13px;
  }

  .main-card {
    height: 380px;
  }

  .card-large {
    height: 570px;
  }

  .blue-div {
    font-size: 12px;
  }

  .select-btn {
    font-size: 20px;
  }

  .bar-label {
    font-size: 13px;
  }

  .news-title {
    font-size: 16px;
  }

  .large-h1{
    font-size: 50px;
  } */

  .home-chart {
    /* height: 1172px; */
    height: 981px;
  }

  /* .table-text{
    font-size: 16px;
  }

  .watchList-head{
    font-size: 17px;
  }

  .watchlist-table th,
  .watchlist-table td {
    background-color: transparent;
    color: #FFF;
    padding: 5px 8px;
    line-height: normal;
    font-size: 15px;
  }

  .badge {
    display: flex;
    width: 36px;
    padding: 2px 12px;
    border-radius: unset;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .news-description,
  .news-source small {
    font-size: 15px;
  }

  .w-t-div {
    max-height: 315px;
    overflow: auto;
  }

  .transaction-table th,
  .bid-ask-table th {
    font-size: 17px;
  }

  .transaction-table td {
    font-size: 15px;
  }

  .m-h-222 {
    max-height: 270px;
    overflow: auto;
  }

  .m-h-170 {
    max-height: 205px;
    overflow: auto;
  }

  .symbol-details-text{
    font-size: 15px;
  }

  .text-danger {
    font-size: 15px;
  }

  .text-success{
    font-size: 15px;
  }

  .gray-text {
    font-size: 18px;
  }

  .low-high{
    font-size: 13px;
  }

  .symbol-details-prev{
    font-size: 15px;
  } */

  .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    font-size: 14px;
  }

  .MuiSlider-valueLabel {
    -webkit-transform: translateY(-100%) scale(1) !important;
    transform: translateY(-68%) scale(1) !important;
  }

  .MuiSlider-marked {
    padding: 10px 1px 0px 0px !important;
  }

  .multi-b-s {
    padding: 5px 32px 4px 32px !important;
  }
}

/* Styles for medium laptop screens */
@media (max-height: 1080px) {

  body,
  .MuiInputBase-input {
    font-size: 11px;
  }

  .medium-heading {
    font-size: 17px !important;
  }

  .small-gray {
    font-size: 9px;
  }

  .main-card {
    height: 278px;
  }

  .card-large {
    height: 570px;
  }

  .blue-div {
    font-size: 12px;
  }

  .select-btn {
    font-size: 15px;
    width: 110%;
  }

  .bar-label {
    font-size: 13px;
  }

  .news-title {
    font-size: 13px;
  }

  .home-chart {
    height: 865px;
  }

  .symbol-details-text {
    font-size: 10px;
  }

  /* .text-danger {
    font-size: 12px;
  }

  .text-success{
    font-size: 12px;
  } */

  .gray-text {
    font-size: 13px;
  }

  .gray-text-1 {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .gray-text-2 {
    font-size: 11px !important;
  }

  .value-text-1 {
    font-size: 11px !important;
  }

  .gray-text-1 {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .gray-text-2 {
    font-size: 11px !important;
  }

  .value-text-1 {
    font-size: 11px !important;
  }

  .low-high {
    font-size: 11px;
  }

  .symbol-details-prev {
    font-size: 13px;
  }

  .m-h-170 {
    max-height: 215px;
    overflow: auto;
  }

  .m-h-222 {
    max-height: 185px;
    overflow: auto;
  }

  .large-h1 {
    font-size: 35px;
  }

  .w-t-div {
    max-height: 215px;
    overflow: auto;
  }

  .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    font-size: 14px;
  }

  /* .css-14gyywz-MuiSlider-valueLabel.MuiSlider-valueLabelOpen{
    -webkit-transform: translateY(-100%) scale(1) !important;
    transform: translateY(-82%) scale(1) !important;
  } */

  .MuiSlider-marked {
    padding: 8px 1px 0px 0px !important;
  }

  .multi-b-s {
    padding: 4px 32px 3px 32px !important;
  }

  #trading-chart iframe {
    height: 868px !important;
  }

}

/* Styles for medium laptop screens */
/* @media (max-height: 900px) {
  body, .MuiInputBase-input {
    font-size: 11px;
  }

  .medium-heading {
    font-size: 17px !important;
  }

  .small-gray {
    font-size: 9px;
  }

  .main-card {
    height: 250px;
  }

  .card-large {
    height: 520px;
  }

  .blue-div {
    font-size: 12px;
  }

  .select-btn {
    font-size: 14px;
  }

  .bar-label {
    font-size: 13px;
  }

  .news-title {
    font-size: 13px;
  }

  .home-chart{
    height: 865px;
  }

  .symbol-details-text{
    font-size: 10px;
  }

  .text-danger {
    font-size: 12px;
  }

  .text-success{
    font-size: 12px;
  }

  .gray-text {
    font-size: 13px;
  }

  .low-high{
    font-size: 11px;
  }

  .symbol-details-prev{
    font-size: 11px;
  }

  .m-h-170 {
    max-height: 105px;
    overflow: auto;
  }

  .m-h-222 {
    max-height: 150px;
    overflow: auto;
  }
} */



@media (max-height: 900px) {

  body,
  .MuiInputBase-input {
    font-size: 10px;
  }

  .medium-heading {
    font-size: 14px !important;
  }

  .small-gray {
    font-size: 10px;
  }

  .main-card {
    height: 243px;
  }

  .card-large {
    height: 504px;
  }

  .blue-div {
    font-size: 12px;
  }

  .select-btn {
    font-size: 13px;
  }

  .holdings {
    font-size: 13px;
  }

  .bar-label {
    font-size: 12px;
  }

  .news-title {
    font-size: 12px;
  }

  .large-h1 {
    font-size: 28px;
  }

  .f-s-14 {
    font-size: 11px;
  }

  .home-chart {
    height: 763px;
  }

  .symbol-details-text {
    font-size: 9px;
  }

  /* .text-danger {
    font-size: 12px;
  }

  .text-success{
    font-size: 12px;
  } */

  .gray-text {
    font-size: 12px;
  }

  .gray-text-1 {
    font-size: 12px !important;
    margin-bottom: -5px !important;
  }

  .gray-text-2 {
    font-size: 10px !important;
  }

  .value-text-1 {
    font-size: 10px !important;
  }

  .gray-text-1 {
    font-size: 12px !important;
    margin-bottom: -5px !important;
  }

  .gray-text-2 {
    font-size: 10px !important;
  }

  .value-text-1 {
    font-size: 10px !important;
  }

  .low-high {
    font-size: 11px;
  }

  .symbol-details-prev {
    font-size: 13px;
  }

  .m-h-170 {
    max-height: 150px;
    overflow: auto;
  }

  .m-h-222 {
    max-height: 160px;
    overflow: auto;
  }

  .w-t-div {
    max-height: 185px;
    overflow: auto;
  }

  .dropdown-btn {
    padding: 0px 25px 1px 9px
  }

  .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    font-size: 14px;
  }

  /* .css-14gyywz-MuiSlider-valueLabel.MuiSlider-valueLabelOpen{
    -webkit-transform: translateY(-100%) scale(1) !important;
    transform: translateY(-82%) scale(1) !important;
  } */

  .MuiSlider-marked {
    padding: 8px 1px 0px 0px !important;
  }

  .support-dialog {
    height: 430px;
  }

  .large-text {
    font-size: 20px;
  }

  .basic-form .form-input {
    font-size: 20px;
  }

  .support-desc {
    height: 130px !important;
  }

  .modal-content {
    width: 97% !important;
  }

  .w-55 {
    width: 55%;
    height: 10%;
  }

  .theme-btn {
    font-size: 20px !important;
  }

  .form-input::placeholder {
    font-size: 20px !important;
  }

  .submit-btn {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .outlined-btn {
    border-radius: 4px !important;
    border: 1px solid #48494C !important;
    color: #898889 !important;
    text-transform: none !important;
    font-size: 12px !important;
    padding: 3px 0px 3px 0px !important;
  }

  .contained-btn {
    background: rgba(49, 124, 255, 0.20) !important;
    border: 1px solid #317CFF !important;
    color: #FFF !important;
    text-transform: none !important;
    font-size: 12px !important;
    padding: 3px 0px 3px 0px !important;
  }

  .MuiButton-sizeSmall {
    font-size: 12px !important;
    padding: 2px 32px 2px 32px !important;
  }

  .multi-b-s {
    padding: 4px 32px 2px 32px !important;
  }

  .btn-group .btn {
    padding: 3px 12px 0px 12px;
    font-size: 12px;
  }

  .indicator-btn {
    font-size: 13px;
  }

  .MuiSlider-marked {
    padding: 11px 0 !important;
  }

  .MuiTypography-root.MuiTypography-h6 {
    font-size: 20px !important;
    color: #FFF !important;
    font-family: 'Aileron', sans-serif;
    padding: 10px 18px 5px 18px;
  }

  .dialog-p {
    font-size: 20px !important;
    color: #898889;
  }

  .MuiTypography-body1 {
    color: #FFF !important;
    font-size: 13px !important;
  }

  .news-source-dialog small {
    color: #898889;
    font-size: 13px !important;
  }

  .dialog-badges {
    width: 32px;
  }

  .ai_summary {
    font-weight: 700;
  }

  .MuiPaper-root {
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.40) !important;
    background: rgba(168, 168, 168, 0.10) !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;
    backdrop-filter: blur(50px) !important;
    color: #FFF !important;
    /* width: 100% !important;
    max-width: 600px !important;
      height: 650px !important; */
  }

  .w-65 {
    width: 65%;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px 0px 4px 0px !important;
  }

  .custom-select {
    background-size: 17px 17px;
  }

  .custom-select .MuiSelect-select {
    font-size: 13px !important;
  }

  .square-select .MuiSelect-select {
    padding: 3px 10px;
  }

  .square-form-control input {
    padding: 5px 10px;
  }

  .control button {
    padding: 3px 10px !important;
  }

  #trading-chart iframe {
    height: 760px !important;
  }

  .progress-container {
    height: 25px !important;
  }

  .text-normal{
    font-size: 12px !important;
  }
  .flex-container{
    gap: 10px !important;
  }

  .text-width {
    width: 40% !important;
  }

  .vertical{
    height: 80px !important;
  }

  .supply-value {
    font-size: 14px !important;
  }

  .use-value {
    font-size: 14px !important;
    margin-right: 5px !important;
  }

  .yield-value {
    font-size: 24px !important;
  }
}



/* Styles for small laptop screens */
@media (max-height: 800px) {

  body,
  .MuiInputBase-input {
    font-size: 10px;
  }

  .medium-heading {
    font-size: 14px !important;
  }

  .small-gray {
    font-size: 9px;
  }

  .main-card {
    height: 206px;
  }

  .card-large {
    height: 426px;
  }

  .blue-div {
    font-size: 10px;
    padding: 6px 6px 3px 6px;
  }

  .select-btn {
    font-size: 12px;
    padding: 2px 10px 2px 10px;
  }

  .bar-label {
    font-size: 12px;
  }

  .news-title {
    font-size: 12px;
  }

  .symbol-details-text {
    font-size: 8px;
  }

  /* .text-danger {
    font-size: 9px;
  }

  .text-success{
    font-size: 9px;
  } */

  .gray-text {
    font-size: 10px;
  }

  .gray-text-1 {
    font-size: 10px !important;
  }

  .sub-header {
    font-size: 11px !important;
  }

  .gray-text-2 {
    font-size: 10px !important;
  }

  .value-text-1 {
    font-size: 10px !important;
  }

  .gray-text-1 {
    font-size: 10px !important;
  }

  .sub-header {
    font-size: 11px !important;
  }

  .gray-text-2 {
    font-size: 10px !important;
  }

  .value-text-1 {
    font-size: 10px !important;
  }

  .low-high {
    font-size: 11px;
  }

  .symbol-details-prev {
    font-size: 11px;
  }

  .m-h-170 {
    max-height: 120px;
    overflow: auto;
  }

  .m-h-222 {
    max-height: 120px;
    overflow: auto;
  }

  .home-chart {
    height: 650px;
  }

  .large-h1 {
    font-size: 21px;
  }

  .w-t-div {
    max-height: 155px;
    overflow: auto;
  }

  .badge {
    display: flex;
    width: 32px;
    padding: 1px 7px;
    border-radius: unset;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .MuiSlider-marked {
    padding: 8px 0 !important;
  }

  .custom-dropdown {
    width: 150px;
  }

  .holdings {
    font-size: 12px;
  }

  .account_sort_by {
    font-size: 13px;
  }

  .custom-select .MuiSelect-select {
    font-size: 10px !important;
  }

  .account_td {
    font-size: 9px;
  }

  .watchlist-table th,
  .watchlist-table td {
    font-size: 10px;
  }

  .acres-table th,
  .acres-table td {
    font-size: 10px !important;
  }

  .acres-table th,
  .acres-table td {
    font-size: 10px !important;
  }

  .dropdown-btn {
    padding: 0px 15px 0px 0px;
    width: 110px;
  }

  .outlined-btn {
    border-radius: 4px !important;
    border: 1px solid #48494C !important;
    color: #898889 !important;
    text-transform: none !important;
    font-size: 10px !important;
    padding: 2px 0px 2px 0px !important;
  }

  .contained-btn {
    background: rgba(49, 124, 255, 0.20) !important;
    border: 1px solid #317CFF !important;
    color: #FFF !important;
    text-transform: none !important;
    font-size: 10px !important;
    padding: 2px 0px 2px 0px !important;
  }

  .MuiButton-sizeSmall {
    font-size: 10px !important;
    padding: 2px 32px 2px 32px !important;
  }

  /* Custom styles for dropdown wrapper */

  .custom-dropdown-chart {
    background-color: transparent;
    padding: 4px 10px 5px 10px;
    font-size: 12px !important;
    color: #FFF;
    cursor: pointer;
    width: 150px;
    text-align: left;
  }

  .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    font-size: 11px;
  }

  .MuiSlider-valueLabel {
    -webkit-transform: translateY(-100%) scale(1) !important;
    transform: translateY(-55%) scale(1) !important;
  }

  .MuiSlider-marked {
    padding: 6px 1px 0px 0px !important;
  }

  .support-dialog {
    height: 380px;
  }

  .large-text {
    font-size: 17px;
  }

  .basic-form .form-input {
    font-size: 17px;
  }

  .support-desc {
    height: 100px !important;
  }

  .modal-content {
    width: 90% !important;
  }

  .w-55 {
    width: 55%;
    height: 10%;
  }

  .theme-btn {
    font-size: 17px !important;
  }

  .form-input::placeholder {
    font-size: 17px !important;
  }

  .submit-btn {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .multi-b-s {
    padding: 3px 32px 3px 32px !important;
  }

  .MuiSlider-marked {
    padding: 11px 0 !important;
  }

  .MuiTypography-root.MuiTypography-h6 {
    font-size: 18px !important;
    color: #FFF !important;
    font-family: 'Aileron', sans-serif;
    padding: 10px 18px 5px 18px;
  }

  .dialog-p {
    font-size: 18px !important;
    color: #898889;
  }

  .MuiTypography-body1 {
    color: #FFF !important;
    font-size: 12px !important;
  }

  .news-source-dialog small {
    color: #898889;
    font-size: 12px !important;
  }

  .dialog-badges {
    width: 32px;
  }

  .ai_summary {
    font-weight: 700;
  }

  .MuiPaper-root {
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.40) !important;
    background: rgba(168, 168, 168, 0.10) !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;
    backdrop-filter: blur(50px) !important;
    color: #FFF !important;
    /* width: 100% !important;
    max-width: 550px !important;
      height: 600px !important; */
  }

  .w-65 {
    width: 65%;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 0px 2px 0px !important;
  }

  .custom-select {
    background-size: 15px 15px;
  }

  .square-select .MuiSelect-select {
    padding: 1px 10px;
  }

  .square-form-control input {
    padding: 3px 10px;
  }

  .square-form-control .MuiInputBase-input::placeholder {
    color: #898889;
  }

  .control button {
    padding: 1px 10px !important;
  }

  .m-h-320 {
    max-height: 220px;
    overflow: auto;
  }

  #trading-chart iframe {
    height: 647px !important;
  }

  .progress-container {
    height: 20px !important;
  }

  .supply-details {
    width: 50% !important;
  }

  .text-normal{
    font-size: 12px !important;
  }

  .yield-value{
    font-size: 18px !important;
    margin-top: 5px !important;
  }

  .text-width{
    width: 42% !important;
  }

  .flex-container{
    gap: 10px !important;
  }

  .acreage-yield-container{
    gap: 8px !important;
  }

  .vertical{
    height: 65px !important;
  }

  .bar-supply-container{
    height: 15px !important;
  }

  .supply-value {
    font-size: 12px !important;
  }

  .use-value {
    font-size: 12px !important;
    margin-right: 5px !important;
  }

  .column-sd{
    gap: 7px !important;
  }
  
  .value-container{
    height: 15px !important;
  }
}

.MuiSlider-marked {
  padding: 11px 0 !important;
}

.MuiTypography-root.MuiTypography-h6 {
  font-size: 22px;
  color: #FFF !important;
  font-family: 'Aileron', sans-serif;
  padding: 10px 18px 5px 18px;
}

.dialog-p {
  font-size: 22px;
  color: #898889;
}

.MuiTypography-body1 {
  color: #FFF !important;
  font-size: 15px;
}

.news-source-dialog small {
  color: #898889;
  font-size: 15px;
}

.dialog-badges {
  width: 34px;
}

.ai_summary {
  font-weight: 700;
}



.ai-btn:hover {
  border: 1px solid #317CFF !important;
}

.control .active {
  border: 1px solid #317CFF !important;
}

.ai-btn {
  border: 1px solid #48494C !important;
}

.ai_block {
  background-color: #317CFF;
  margin-left: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  border-radius: 2px;
  margin-bottom: 5px !important;
}

.setting-dialog h6,
.setting-dialog p {
  font-size: 20px;
}

.square-form-control-update input {
  font-size: 15px !important;
  color: #898889 !important;
  padding: 9px 10px;
  border: 1px solid #48494C;
  border-radius: 6px;
  background: transparent;
}

/* Override hover and focus styles */
.square-form-control-update .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control-update .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control-update {
  width: 100%;
}

.square-form-control-update .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .widget-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.widget-card {
  width: 100% !important;
  padding: 20px 4% !important;
  backdrop-filter: blur(50px) !important;
  margin: 20px !important;

  background: transparent !important;
  background-image: url('../public/images/card-bg.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: none !important;
} */


.setting-dialog .dialog-body {
  display: flex;
  height: 480px;
  /* Adjust height as needed */
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
}

.sidebar {
  /* width: 200px; Fixed width to contain all sidebar items */
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
}

.sidebar div {
  /* padding: 4px; */
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  width: 160px;
  /* Fixed width for uniform look */
  flex-shrink: 0;
  margin-bottom: 5px;
  /* Adjusts spacing to keep items aligned */
  box-sizing: border-box;
  /* Ensures padding and borders don't expand width */
}

.sidebar div.active {
  background: rgba(49, 124, 255, 0.20);
  border: 1px solid #317CFF;
  color: #FFF;
  border-radius: 5px;
}

.sidebar div p {
  margin: 0;
  padding-left: 8px;
}

.content-area {
  flex-grow: 1;
  padding-left: 10px;
  max-height: calc(480px - 20px);
}

.divider {
  margin-left: 10px;
  width: 1px;
  background-color: #48494C;
  margin-top: -35px !important;
}

.add-button {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.widget-container:hover .add-button {
  opacity: 1;
}


.square-form-control-search input {
  font-size: 16px !important;
  color: #898889;
  padding: 4px 10px;
  border: 1px solid #48494C;
  border-radius: 4px;
  background: transparent;
}

.square-form-control-search .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control-search .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.no-arrows {
  -moz-appearance: textfield;
  /* For Firefox */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* For Chrome, Safari, Edge, and Opera */
  margin: 0;
  /* Removes any margin around the spin buttons */
}


.custom-nav-dropdown .dropdown-option:hover {
  color: #fff;
  border-radius: 4px;
  border: 1px solid var(--Blue, #317CFF);
  background: rgba(49, 124, 255, 0.20);
}

.preformance-dropdown {
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.40) !important;
  background: rgba(168, 168, 168, 0.10) !important;
  /* Uncomment this if you want the background */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;
  -webkit-backdrop-filter: blur(50px) !important;
  backdrop-filter: blur(50px) !important;
  color: #FFF !important;
  font-size: 12px;
  --bs-dropdown-min-width: 5rem;
}

.tbody-gray td {
  color: #898889 !important;
  /* Force override the global white color */
}

.square-form-control-setting input {
  width: 80%;
  border: 1px solid #48494C;
  border-radius: 4px;
  padding: 2px 5px 0px 5px;
  background-color: transparent;
  color: #898889;
  font-size: 10px;
}

.square-form-control-setting .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control-setting .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #48494C !important;
}

.square-form-control-setting .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 70%;
}


/* .square-form-control-setting .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary {
  width: 50% !important;
}

.square-form-control-setting .MuiFormControl-root .MuiFormControl-marginNormal .MuiFormControl-fullWidth{
  width: 50% !important;
} */


.setting-td {
  width: 25% !important
}

#trading-chart iframe {
  position: relative;
  z-index: 1;
  height: 760px;
  border: 2px solid rgba(255, 255, 255, 0.20);
  border-radius: 10px;
}

.sub-header {
  font-size: 14px;
  font-weight: 700;
}

.acres-table {
  width: 100%;
  /* Makes the table span the full width of its container */
}

.acres-table th {
  font-weight: 400;
  color: var(--Word-Grey, #898889);
  font-size: 14px;
  font-style: normal;
}

.acres-table td {
  font-weight: 700;
  color: var(--White, #fff);
  font-size: 14px;
  font-style: normal;
}

.stock-bar-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;

}

.bar-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.progress-container {
  width: 60%;
  height: 30px;
  background-color: transparent;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease;
}

p {
  font-size: 1rem;
}

.gray-text-1 {
  font-weight: 400;
  color: var(--Word-Grey, #898889);
  font-size: 14px;
  font-style: normal;
  margin-bottom: -5px;
}

.gray-text-2 {
  font-weight: 400;
  color: var(--Word-Grey, #898889);
  font-size: 12px;
  font-style: normal;
  width: 100px;
}

.value-text-1 {
  font-weight: 400;
  color: var(--Word-Grey, #fff);
  font-size: 12px;
  font-style: normal;
}

.supply-details {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle-beginning {
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border-radius: 50%;
}

.text-circle-gap {
  display: flex;
  gap: 8px;
  /* Space between the circle and text */
  align-items: center;
}

.ai-button-container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.ai-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: var(--Blue, #317CFF);
  width: 26px;
  height: 16px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-layout {
  display: flex;
  gap: 8px;
  align-items: center;
}


.dropdown-button-container {
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid var(--Dark-grey, #48494C);
  width: max-content;
}

.year-text {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.dotted-line {
  position: absolute;
  /* Positioned relative to chart-container */
  top: 0;
  left: 0;
  /* Center the dotted-line horizontally */
  width: 92%;
  /* Dotted background is 90% of chart-container */
  height: 90%;
  /* Dotted background height */
  background-image: radial-gradient(circle, #646464 1.5px, transparent 1.5px);
  /* Dots pattern */
  background-repeat: repeat;
  background-size: 20px 20px;
  z-index: 1
}

.chart-wrapper {
  position: relative;
  /* Position relative for z-index to work */
  z-index: 2;
  /* Higher z-index to ensure it appears above the dotted background */
  width: 100%;
  height: 100%;
  padding-top: 12px;
}

.acreage-yield-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.supply-demand-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.first-row-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  width: 50%;
}


.flex-container {
  display: flex;
  gap: 20px;
}

.supply-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.text-normal {
  color: var(--White, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-width {
  width: 34%;
}

/* .value-container {
  border-radius: 4px;
  background: var(--Green, #40C796);
  display: flex;
  flex-grow: 1;
  padding: 1px 0px 3px 8px;
  height: 18px;
  color: black;
} */

.vertical {
  border-left: 1px solid #48494C;
  /* Set color and thickness */
  height: 90px;
  /* Full height of the parent container */
  width: 1px;
  background-color: #48494C;
  margin-left: 0px;
}

.yield-value {
  color: var(--Green, #40C796);
  text-align: right;
  margin-top: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.flex-col-1-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  text-align: right;
  width: 50%;
}

.column-second {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 12px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}

.column-sd {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}

.value-container {
  position: relative;
  flex-grow: 1;
  /* Fill remaining space */
  background: transparent;
  /* Light gray for unfilled part */
  border-radius: 4px;
  height: 18px;
  overflow: hidden;
  /* Clip the progress bar fill */
}

.value-fill {
  height: 100%;
  border-radius: 4px;
  /* Rounded edges only on the left */
  transition: width 0.3s ease-in-out;
  /* Smooth animation */
}

/* Planted fill color */
.value-fill.planted {
  background: var(--Green, #40C796);
}

/* Harvested fill color */
.value-fill.harvested {
  background: #317CFF;
}

.value-text {
  position: absolute;
  left: 8px;
  /* Padding inside the container */
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #1F2025;
}

.supply-use-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: white;
}


.bar-supply-container {
  display: flex;
  width: 100%;
  height: 18px;
  overflow: hidden;
  border-radius: 10px;
}

.supply-bar {
  background-color: #40C796;
  /* Green */
  padding-left: 8px;
  color: black;
  line-height: 18px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
}

.use-bar {
  background-color: #E74C3C;
  /* Red */
  text-align: right;
  padding-right: 8px;
  color: black;
  line-height: 18px;
  clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%);
  margin-left: -13px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


.values-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}

.supply-value,
.use-value {
  color: white;
  font-size: 16px;
}

/* hr {
  width: 92%;
} */

/* .custom-dropdown-container {
  position: relative;
  display: inline-block;
  width: 0px;
  border: 1px solid var(--Dark-grey, #48494C);
  border-radius: 4px;
}

.custom-dropdown {
  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none; 

  width: 70px;
  background: transparent;
  color: white;
  outline: none;
  border: none;
}

.dropdown-icon {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; 
  color: white;
} */



.dropdown-icon {
  position: absolute;
  right: 0px;
  /* Adjust based on your design */
  top: 50%;
  transform: translateY(-50%);
}

select {
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid var(--Dark-grey, #48494C);
  width: max-content;
  background-color: transparent;
  outline: none;
}

.empty-card {
  background: unset !important;
  border: none !important;
}

.MuiToggleButtonGroup-grouped {
  height: 35px;
  padding: 5px 20px !important;
  text-transform: capitalize !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.MuiToggleButtonGroup-root {
  border-radius: 50px !important;
}

.MuiToggleButtonGroup-root .Mui-selected {
  background-color: #317CFF !important;
  color: #FFF !important;
  border-radius: 50px !important;
  height: 29px;
  margin-top: 3px !important;
}

.price-feature {
  color: rgba(255, 255, 255, 0.80);
  font-size: 14px;
}

.price-submit {
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.10) !important;
  text-transform: capitalize !important;
}

.payment-card {
  border-radius: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.40) !important;
  background: rgba(31, 32, 37, 0.70) !important;
  /* Blur */
  backdrop-filter: blur(50px) !important;
}